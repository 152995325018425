@import './scss/theme.scss';
@import './scss/mixins.scss';
@import './scss/chart.scss';
@import './scss/login.scss';
//@import './scss/material-overwrites-deprecated.scss';
@import './scss/modal.scss';
@import './scss/popover.scss';
@import './scss/report.scss';
@import './scss/layer.scss';
@import './scss/legend.scss';
@import './scss/sidebar.scss';
@import './scss/table.scss';
@import './scss/new-design.scss';
@import './scss/variables.scss';
@import './scss/widget.scss';
@import './scss/custom-menu.scss';

// Material
@import './scss/material/mat-tooltip';
@import './scss/material/mat-table';
@import './scss/material/mat-badge';
@import './scss/material/mat-form-field';
@import './scss/material/mat-checkbox';
@import './scss/material/mat-button-toggle-group.scss';

// css fonts
//@import '../../node_modules/material-design-icons/iconfont/material-icons.css';
@import '@fortawesome/fontawesome-free/css/all.css';
@import './scss/fa-icon.scss';

// 3rd party css
@import 'leaflet/dist/leaflet.css';
@import 'leaflet-draw/dist/leaflet.draw.css';

@import 'beautifymarker/leaflet-beautify-marker-icon.css';

@import './scss/leaflet-override.scss';

// Variables for consistency
$hover-color: #bbb;
$ei-bg: #074569;
$disabled: #999;
$error: #ff0000;
$success: #008000;
// If this is changed, chart line colors and nom legend will need to be manually updated
$colorblindOverride: #e66101;
$override: #6a51a3;

//New Design colors

* {
    //position: relative;
    margin: unset;
    //AZM took this out to fix some issues with the release notes.
    //padding: 0;
}

.widthLimiter {
    max-width: 1100px;
    margin: auto !important;
    transition: all 1s ease-in-out;
    //padding-bottom: 24px;
}

@media (max-width: 1100px) {
    .widthLimiter {
        margin-left: 10px;
        margin-right: 10px;
        //padding-bottom: 24px;
    }
}

*,
*:after,
*:before {
    box-sizing: inherit;
}

svg {
    pointer-events: none;

    * {
        pointer-events: all;
    }
}

body,
html {
    //background: #EEE;
    position: relative;
    min-height: 100%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    font-family: 'Roboto', sans-serif;
    font-size: 18px;
}

ol,
ul {
    list-style: none;
}

router-outlet {
    display: none;
}

button {
    cursor: pointer;
    font-family: 'Open Sans', sans-serif;
    background: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-width: 1px;

    &.regular {
        font-family: Roboto, sans-serif;
        font-weight: 400;
    }
}

input {
    padding: 5px;
}

a {
    cursor: pointer;
    //text-decoration: none;
    //color: #1f7cb6;
}

header {
    //padding-top: 10px;
    //padding-bottom: 10px;
    h1,
    h2,
    h3 {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.banner-text {
    position: absolute;
    padding: 3px;
    top: 0;
    right: 0;
    left: 0;
    height: 23px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.5px;
    color: white;
    background-color: #00731d;
    text-align: center;
    pointer-events: none;
    z-index: 2;
    box-shadow: 0 1px 1px #666;
}

ul,
li {
    &.bulleted {
        padding-left: 10px;
        margin-left: 10px;
        list-style: disc;

        ul,
        li {
            &.bulleted {
                list-style: square;
            }
        }
    }
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.mdc-evolution-chip-set__chips {
    width: 100% !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
    width: 100% !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}

.ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.full-screen {
    max-width: 100%;
}

.no-bottom-padding {
    padding-bottom: 0;
}

.disabled,
.disabled > * {
    color: $disabled !important;
    pointer-events: none !important;
}

.center {
    text-align: center;
}

.error {
    color: $error;
}

.download-icon,
.close-icon {
    &:hover {
        cursor: pointer;
    }
}

.clickable {
    cursor: pointer !important;
    pointer-events: all;
}

// Snack bar classes
.popup-text {
    white-space: pre-line;
}

.failure .mat-simple-snackbar-action {
    color: #fff;
}

[hidden] {
    display: none !important;
}

.half-width {
    width: 50%;
}

.wide {
    width: 100% !important;
}

.tall {
    height: 100%;
}

.hide-horizontal {
    width: 0;
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s all ease-in-out;

    &.show {
        width: 100%;
        max-height: 100%;
        opacity: 1;
        pointer-events: all;
    }
}

.hide {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 500ms ease-in-out;

    &.show {
        max-height: 3000px;
        overflow: visible;
        opacity: 1;
    }
}

.center {
    text-align: center;
}

.invisible {
    animation: opacity 0.5s ease-in-out;
    opacity: 0;
    pointer-events: none !important;

    &.visible {
        opacity: 1;
        pointer-events: all !important;
    }
}

.ei-selected {
    background-color: $ei-bg;
    color: #fff;
}

.page-header {
    color: $ei-bg;
    padding: 30px;
    text-align: center;
    border-bottom: 1px solid lightsteelblue;
}

// Override
.override-banner {
    //background-color: $override;
}

.override-text {
    color: $override;
}

tr.override-text {
    & * {
        color: $override;
    }
}

.override-state {
    background-color: $override;
}

.color-blind-friendly {
    & .override-banner {
        //background-color: $colorblindOverride;
    }

    & .override-text {
        color: $colorblindOverride;
    }

    & .override-state {
        background-color: $colorblindOverride;
    }

    & tr.override-text {
        & * {
            color: $colorblindOverride;
        }
    }
}

// Open layers override
eaglei-map {
    & .ol-scale-line {
        margin-left: 15px !important;
    }
}

//New Design
.invalid-bg {
    background-color: $invalid !important;
}

.invalid {
    color: $invalid !important;
}

.valid-bg {
    background-color: $valid !important;
}

.valid {
    color: $valid !important;
}

.bold {
    font-weight: bold;
}

.no-border {
    border: none !important;
}

.flex-fill {
    margin: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
}

.main-page-view {
    & > *:not(.no-padding) {
        padding: 20px 20px 0;
    }
}

.no-events {
    pointer-events: none !important;
}

// Style for account dropdown menu
.account-dropdown-menu {
    width: 250px;
    color: #7b7b7b;
}

.leaflet-overlay-pane,
.leaflet-marker-pane {
    z-index: 700 !important;
}

.disable-popover {
    display: none;
}

.enable-popover {
    display: block;
}

.eaglei-popover {
    animation: 1s;
    pointer-events: none;
    position: absolute;
    z-index: 1000;

    &.interactive {
        pointer-events: all;
    }
}

.fake-link {
    color: $link-color;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

eaglei-date-time-picker {
    & *.mat-form-field-infix {
        display: flex;
    }

    & .mat-form-field-suffix {
        font-size: 11px;
        width: 20px;
        height: 20px;
        top: 2px !important;
    }
}

.mat-mdc-form-field.multiple-inputs {
    .mat-mdc-form-field-infix {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .mat-datepicker-toggle .mat-mdc-icon-button {
        height: 40px;
        width: 40px;
        padding: 8px;
    }
}

.mat-mdc-select.time-selector {
    padding-left: 4px;
    width: fit-content;

    .mat-mdc-select-value {
        margin: 0;
    }

    .mat-mdc-select-arrow-wrapper {
        display: none;
    }
}

gridster-item {
    div.gridster-item-resizable-handler {
        z-index: 1000;
    }
}
